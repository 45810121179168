.form-couple {
    display: flex;
    align-items: center;
    margin: 2vh;
    justify-content: space-between;
}

.form-text-field {
    width: 180px;
}
.form-text-field-desc {
    width: 195px;
}

@media (max-width:635px) and (orientation:portrait) {
    .form-couple{
    margin: 1vh;
    flex-wrap: wrap;
    }
    .form-text-field {
        width: 100%;
    }
}

@media (min-width:636px) and (max-width:960px) {
    .form-text-field {
        max-width: 150px;
    }
}

.form-text-field:only-child {
    flex: 1;
}

@media (min-width:636px) and (max-width:960px) {
    .form-text-field-hospital {
        width: 180px;
        max-width: unset;
    }
    .form-text-field-desc {
        width: 195px;
    }
}