.hrline {
    width: 80%;
    display: flex; 
    flex-direction: row;
    align-self: center;
    font-size: 18px;
    color: #808080;
    margin-bottom: 20px;
}

.hrline::before,.hrline::after{
    content: ""; 
    flex: 1 1; 
    border-bottom: 2px solid #808080; 
    margin: auto;
}