.App {
  text-align: center;
  padding: 0;
  margin: 0;
  height:100%;
  min-height: 100vh;
  background: #F3F1EE;
  color: #2c2c2c;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.rootPadding{
  padding-top: 0px;
}
.footerSection{
  background-color:#ffffff;
   color:#9B51E0;
}
.footerBody{
  display: inline-flex; 
  padding: 0px 0 15px 0;
}
.footerText{
  padding:0px 10px;
}
.MuiButton-text {
  padding: 6px 0px !important;
}
@media (max-width: 375px){
  .rootPadding{
    padding-top: 0px;
  }
}
@media (max-width: 414px){
  .rootPadding{
    padding-top: 0px;
  }
  .footerBody{
    display: initial; 
    padding: 0px 0 15px 0;
  }
}
.App > div {
  flex: 1 0 auto;
}
footer {
  flex-shrink: 0;
  position: relative;
  z-index: 1250;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #9B51E0;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

